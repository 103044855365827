const actions = {
  SET_STATE: 'business/SET_STATE',
  FETCH_ALL_BUSINESS: 'business/FETCH_ALL_BUSINESS',
  SET_STATE_DETAIL: 'business/SET_STATE_DETAIL',
  FETCH_BUSINESS_DETAIL: 'business/FETCH_BUSINESS_DETAIL',
  SET_STATE_USERS: 'business/SET_STATE_USERS',
  ADD_BUSINESS_NOTES: 'business/ADD_BUSINESS_NOTES',
  LIST_BUSINESS_NOTES: 'business/LIST_BUSINESS_NOTES',
  ACTIVE_DEACTIVE_BUSINESS: 'business/ACTIVE_DEACTIVE_BUSINESS',
  ACTIVE_DEACTIVE_USERS: 'business/ACTIVE_DEACTIVE_USERS',
  FORCE_UPDATE_PASSWORD: 'business/FORCE_UPDATE_PASSWORD',
  DELETE_STRIPE_ACCOUNT: 'business/DELETE_STRIPE_ACCOUNT',
  CHANGE_STATEMENT_DESCRIPTOR: 'business/CHANGE_STATEMENT_DESCRIPTOR',
  SYNC_BUSINESS_STRIPE_DATA: 'business/SYNC_BUSINESS_STRIPE_DATA',
  CLONE_BUSINESS: 'business/CLONE_BUSINESS',
  RESTRICT_BUSINESS: 'business/RESTRICT_BUSINESS',
  TOGGLE_ONBOARDING: 'business/TOGGLE_ONBOARDING',
}

export default actions
