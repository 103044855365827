export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-briefcase',
      url: '/dashboard',
    },
    {
      title: 'Business',
      key: 'business',
      icon: 'fe fe-briefcase',
      url: '/business',
    },
    {
      title: 'Restrict Business',
      key: 'restrictBusiness',
      icon: 'fe fe-briefcase',
      url: '/restrict-business',
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fe fe-users',
      url: '/users',
    },
    {
      title: 'Subscriptions',
      key: 'subscriptions',
      icon: 'fe fe-package',
      url: '/subscriptions',
    },
    {
      title: 'PeyMe Lynk',
      key: 'PeyMe Lynk',
      icon: 'fe fe-airplay',
      url: '/peyme',
    },
    {
      title: 'Invoices',
      key: 'Invoices',
      icon: 'fe fe-layout',
      url: '/invoices',
    },
    {
      title: 'Checkouts',
      key: 'Checkouts',
      icon: 'fe fe-shopping-cart',
      url: '/checkouts',
    },
    {
      title: 'Payments',
      key: 'payments',
      icon: 'fe fe-dollar-sign',
      url: '/payments',
    },
    {
      title: 'Ready for Payments ',
      key: 'readyforpayment',
      icon: 'fe fe-dollar-sign',
      url: '/readyforpayments',
    },
    {
      title: 'Refunds',
      key: 'refunds',
      icon: 'fe fe-dollar-sign',
      url: '/refunds',
    },
    {
      title: 'Payouts',
      key: 'payouts',
      icon: 'fe fe-dollar-sign',
      url: '/payouts',
    },
    {
      title: 'Requests',
      key: 'requests',
      icon: 'fe fe-edit-3',
      url: '/requests',
    },
    {
      title: 'Wallets',
      key: 'wallets',
      icon: 'fe fe-credit-card',
      url: '/wallets',
    },
    {
      title: 'Wallet Transaction',
      key: 'wallet_transactions',
      icon: 'fe fe-list',
      url: '/wallet_transactions',
    },
    {
      title: 'Wallet Ledger',
      key: 'wallet_ledger',
      icon: 'fe fe-credit-card',
      url: '/wallet_ledger',
    },
    {
      title: 'Plans',
      key: 'plans',
      icon: 'fe fe-package',
      url: '/plans',
    },
    {
      title: 'Banners',
      key: 'banners',
      icon: 'fe fe-film',
      url: '/banners',
    },
    {
      title: 'Staff',
      key: 'staff',
      icon: 'fe fe-users',
      url: '/staff',
    },
    {
      title: 'Countries',
      key: 'country',
      icon: 'fe fe-zap',
      url: '/countries',
    },
    // {
    //   title: 'Invoice Bad Data',
    //   key: 'invoices',
    //   icon: 'fe fe-alert-triangle',
    //   url: '/invoices/bad-data',
    // },
    {
      title: 'System management',
      key: 'system_management',
      icon: 'fe fe-shield-off',
      url: '/system_management',
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'fe fe-book',
      url: '/reports',
    },
  ]
}
