const actions = {
  SET_STATE: 'users/SET_STATE',
  FETCH_ALL_USERS: 'users/FETCH_ALL_USERS',
  FETCH_USER_USER: 'users/FETCH_USER_USER',
  LIST_USERS_NOTES: 'users/LIST_USERS_NOTES',
  ADD_USERS_NOTES: 'users/ADD_USERS_NOTES',
  ACTIVE_DEACTIVE_USERS: 'users/ACTIVE_DEACTIVE_USERS',
  RESET_PASSWORD_USERS: 'users/RESET_PASSWORD_USERS',
  ASSUME_USER: 'user/Assume_USER',
  CHANGE_CONNECTED_EMAIL: 'users/CHANGE_CONNECTED_EMAIL',
}

export default actions
